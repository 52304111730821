import React, { useLayoutEffect } from 'react';
import './App.css';
import Home from './features/public/home/HomeView';
import Products from './features/public/products/ProductsView';
import Applications from './features/public/applications/ApplicationsView';
import Order from './features/public/order/OrderView';
import { Route, Routes as RoutesContainer, useLocation } from 'react-router-dom';
import PrivacyPolicy from './features/public/legal/PrivacyPolicy';
import CGU from './features/public/legal/CGU';
import LegalMentions from './features/public/legal/LegalMentions';
import Studio from './features/public/studio/StudioView';
import Admin from './features/public/admin/Admin';
import ClientInfoView from './features/client/client-info/ClientInfoView';
import TeslaSlider from 'components/TestScroll';

function Routes() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
      <RoutesContainer>
        <Route path="/" Component={Home} />
        <Route path="/scroll" Component={TeslaSlider} />
        <Route path="/produits/:target?" Component={Products} />
        <Route path="/studio" Component={Studio} />
        <Route path="/realisations" Component={Applications} />
        <Route path="/commander" Component={Order} />
        <Route path="/politque-de-confidentialite" Component={PrivacyPolicy} />
        <Route path="/cgv" Component={CGU} />
        <Route path="/mentions-legales" Component={LegalMentions} />
        <Route path="/admin" Component={Admin} />
        <Route path="/admin/info-personnelles" Component={ClientInfoView} />
        {/* <Route component={NotFound} /> */}
      </RoutesContainer>
  );
}

export default Routes;
