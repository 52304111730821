import React, { FC, useEffect, useRef } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import studio_code from '../../../assets/img/studio-code.png';
import rocket from '../../../assets/img/rocket.png';
import HeliosStudio from '../../../assets/videos/HOL_SH0040_v0020.mp4';
import ContactForm from '../../../components/forms/contact-form/ContactForm';
import { CssType } from '../types/commun';
import { useMediaQuery, useTheme } from '@mui/material';

interface StudioSceneProps { }

const StudioScene: FC<StudioSceneProps> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [])
    return (<HomeLayout>
        {/* Section 1 */}
        <div style={styles.section1.container}>
            <img src={studio_code} alt="studio_code" style={styles.section1.img} />
            <div style={styles.section1.txt}>
                Une équipe d’experts à votre service
            </div>
        </div>
        {/* Section 2 */}
        <div style={styles.section2.container}>
            Profitez des meilleurs contenus à afficher sur vos supports holographiques.
            <br />Chaque contenu est personnalisé et réalisé sur demande.
        </div>
        <div style={{
            maxWidth: isMobile ? undefined : 640 * 2,
            margin: '0 auto',
            // display: 'flex',
            // justifyContent: 'center', // Horizontally center
            // alignItems: 'center', // Vertically center
        }}>

            {/* Section 3 */}
            <div style={{ display: 'flex' }}>

                <div style={{ ...styles.section3.container, flexDirection: isMobile ? 'column' : 'row' }}>
                    <div style={styles.section3.innerDiv}>
                        <h2 style={styles.section3.title}>Différentes offres pour satisfaire tous les budgets !</h2>
                        <p>Que ce soit simplement pour l’animation d’un logo, ou pour la création complète d’un produit en 3D, notre équipe polyvalente saura s’adapter à votre demande.
                            <br /> <b>A partir de 100€.</b></p>
                        <p>Nos services inclus :</p>
                        <ul>
                            <li>L’analyse et la suggestion du contenu idéal en fonction de votre secteur.</li>
                            <li>La mise en place d’une offre adaptée à votre besoin et votre budget.</li>
                            <li>La création du contenu personnalisé et adapté aux supports Helios et Solaris.</li>
                            <li>L’envoi du contenu et la diffusion sur votre support.</li>
                        </ul>
                    </div>
                    <div>
                        <video
                            ref={videoRef}
                            playsInline
                            autoPlay
                            loop
                            muted
                            style={{
                                width: isMobile ? '100%' : 640, // Set the desired width
                                height: isMobile ? 'auto' : 640, // Adjust the height accordingly
                                objectFit: 'cover', // Maintain aspect ratio and cover parent container
                            }}>
                            <source src={HeliosStudio} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {/* <img src={rocket} alt="studio_code" style={styles.section3.img} /> */}
                    </div>
                </div>
            </div>
            {/* Section 4 */}
            <div style={{ display: 'flex' }}>
                <div style={{ ...styles.section4.container, flexDirection: isMobile ? 'column' : 'row-reverse' }}>
                    <div style={styles.section4.innerDiv}>
                        <h2 >Modélisez vos produits en 3D</h2>
                        <p>Pour communiquer efficacement et mettre en avant vos produits, le Studio by Starget Holographics vous propose la modélisation de vos produits, puis l’animation de ceux-ci pour une diffusion idéale.</p>
                        <p>Cette offre est réservée aux clients équipés des supports Helios et Solaris, et est assujettie à des frais supplémentaires.</p>
                        <p>Plus d’informations disponibles auprès des équipes commerciales.</p>
                    </div>
                    <div>
                        <img src={rocket} alt="studio_code" style={isMobile ? styles.section3.img : { width: 640, height: 640 }} />
                    </div>
                </div>
            </div>
        </div>
        {/* contact Form */}
        <ContactForm />
    </HomeLayout >);
}

export default StudioScene;

const styles: CssType = {
    section1: {
        container: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-around'
        },
        img: { width: '100%', height: 'auto' },
        txt: {
            textAlign: 'center',
            position: 'absolute',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 36,
            color: '#FFFFFF',
        },
    },
    section2: {
        container: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: 18,
            marginTop: 50,
            marginBottom: 50,
            textAlign: 'center',
            color: '#000000',
        }
    },
    section3: {
        container: {
            display: 'flex',
            placeContent: 'space-around space-between',
            alignItems: 'center',
        },
        innerDiv: { padding: 20 },
        title: { color: '#0E1B4D' },
        img: { width: '100%', height: 'auto' },
    },
    section4: {
        container: {
            marginTop: -7,
            display: 'flex',
            placeContent: 'space-around space-between',
            alignItems: 'center',
            flexDirection: 'row-reverse',
        },
        innerDiv: { padding: 20, color: '#0E1B4D' },
    }
}
