import * as React from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import { Typography } from '@mui/material';


interface AdminProps {

}

const Admin: React.FC<AdminProps> = () => {
    return (<>
        <HomeLayout>
            <div style={{
                height: '100vh', /* Sets the height of the parent container to 100% of the viewport height */
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <Typography variant='h1' textAlign={'center'}>
                    COMING SOOON!
                </Typography>
            </div>

        </HomeLayout>
    </>);
}

export default Admin;