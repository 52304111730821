import { Container } from '@mui/material';
import * as React from 'react';
import PersonalInfoForm from './components/PersonalInfoForm';
import ChangePasswordForm from './components/ChangePasswordForm';
import HomeLayout from '../../../components/layouts/HomeLayout';


interface ClientInfoSceneProps {

}

const ClientInfoScene: React.FC<ClientInfoSceneProps> = () => {
    return (<>
        <HomeLayout>
            <Container maxWidth="sm">
                <div>
                    <h2>Personal Information</h2>
                    <PersonalInfoForm />
                </div>
                <div>
                    <h2>Change Password</h2>
                    <ChangePasswordForm />
                </div>
            </Container>
        </HomeLayout>
    </>);
}

export default ClientInfoScene;