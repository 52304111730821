export const sections = [
    {
        title: 'Une gestion simplifiée',
        description: `Pour allumer/éteindre, régler la luminosité, ajouter/supprimer des vidéos ou simplement sélectionner la vidéo à diffuser sur votre support Solaris, plusieurs solutions sont possibles :
        Une interface en ligne pour prendre le contrôle de son panneau, qui permet aussi aux équipes Starget Holographics d’intervenir à distance selon les besoins du client.
        Un écran tactile intégré au support, sur lequel le client peut insérer du contenu par clé USB.
        Pour la création de contenu à diffuser, Starget Holographics vous fournira les caractéristiques exactes des vidéos à diffuser sur votre panneau.
        L’équipe du Studio est également disponible pour créer votre contenu personnalisé.
        Pour commander votre contenu à diffuser, rendez-vous sur la page Studio.`,
        btnText: 'Studio'
    }
]

export const products = [
    {
        name: 'Helios',
        title: 'Résolution : 1024*1024 par hélice',
        description: '5500cd/m²',
        caracteristics: {
            Hauteur: "178cm",
            Largeur: "135cm",
            Profondeur: "45cm",
            Poids: "??kg",
            Résolution: "1024x1024 / hélice",
            Luminosité: "5500cd/m²",
            Connectivité: "Port USB + Prise en main à distance",
            Hélices: "6",
        }
    },
    {
        name: 'Solaris',
        title: 'Solaris',
        description: '1900cd/m²',
        caracteristics: {
            Hauteur: `163cm sur pied
            89cm sans pied`,
            Largeur: "69cm",
            Profondeur: "18cm",
            Poids: "??kg",
            Résolution: "1024x1024 / hélice",
            Luminosité: "1900cd/m²",
            Connectivité: "Port USB + Prise en main à distance",
            Hélices: "1",
        }
    }
]

