import React from 'react';
import TextField from '@mui/material/TextField';

const ChangePasswordForm = () => {
  return (
    <form>
      <TextField
        type="password"
        label="Ancien mot de passe"
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="password"
        label="Nouveau mot de passe"
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="password"
        label="Confirmer le nouveau mot de passe"
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    </form>
  );
};

export default ChangePasswordForm;
