import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
/**
 * TODO: 
 * 0 - Deliver & deploy strapi (first);
 * 1 - download brochure for Helios | Solaris;
 * 2 - finish Applications page;
 * 2.1 - solve slider problem
 * 3 - deliver app;
 * 
 */
function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
