import { AppBar, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import logo from '../../../assets/img/logo-black.png'
import logoStar from '../../../assets/img/logo-star.png'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
interface HeaderProps {
    menuItems: {
        text: string;
        path: string;
    }[]
}

const Header: FC<HeaderProps> = ({
    menuItems
}) => {
    const navigate = useNavigate();
    const pathName = window.location.pathname
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)'); // Change the breakpoint as needed
    const toggleDrawer = () => {
        setOpenDrawer(true);
    };
    return (<AppBar position={`${isMobile ? 'relative' : 'static'}`} color='transparent' style={{ backgroundColor: 'white'}}>
        <Toolbar >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography onClick={() => { navigate('/') }} variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer'}}>
                        <img src={logoStar} width={77/1.8} height={65/1.8} alt='logo-star' />
                        <img src={logo} width={250/1.8} height={82/1.8} alt='logo-header' />
                    </Typography>
                </Grid>
                <Grid item>
                    {isMobile ? (
                        <>
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="right" open={openDrawer} >
                                <Grid item>
                                    <Typography onClick={() => { navigate('/') }} variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer', padding: 2 }}>
                                        <img src={logoStar} width={77/1.8} height={65/1.8} alt='logo-star' />
                                        <img src={logo} width={250/1.8} height={82/1.8} alt='logo-header' />
                                    </Typography>
                                </Grid>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="close"
                                    onClick={() => { setOpenDrawer(false) }}
                                    style={{ position: 'absolute', top: 10, right: 10 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <List sx={{ width: '100vw', marginTop: 15 }}>
                                    {menuItems?.map((item) => (<ListItem  >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                style: {
                                                    fontFamily: 'Poppins',
                                                    fontSize: 25,
                                                    fontWeight: 'bolder'
                                                }
                                            }}
                                            secondaryTypographyProps={{
                                                style: {
                                                    fontFamily: 'Poppins',
                                                    fontSize: 20,
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            onClick={() => { navigate(item.path) }}
                                            primary={pathName === item.path ? item.text : undefined}
                                            secondary={pathName === item.path ? undefined : item.text}
                                        />
                                    </ListItem>))}
                                    <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/admin') }}>
                                        <AccountCircleOutlinedIcon />
                                        <span style={{ marginLeft: 5 }}>Se connecter</span>
                                    </ListItem>
                                </List>
                            </Drawer>
                        </>
                    ) : (<>
                        {menuItems?.map((item) => (<Button
                            color="inherit"

                            onClick={() => { navigate(item.path) }}
                            sx={{ fontFamily: '', margin: '0 45px', fontWeight: pathName === item.path ? 'bold' : 'normal' }}>
                            {item.text}
                        </Button>))}
                        <Button onClick={() => {
                            // Handle navigation to mgt interface
                            navigate('/admin')
                        }}> <AccountCircleOutlinedIcon /></Button>
                    </>)}
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>);
}

export default Header;