import React, { FC, PropsWithChildren } from 'react';
import Header from '../commun/header/Header';
import { menuItems } from '../../datalayer/commun/header';
import Footer from '../commun/footer/Footer';


interface HomeLayoutProps {

}

const HomeLayout: FC<PropsWithChildren<HomeLayoutProps>> = ({
    children
}) => {
    return (<>
        <Header menuItems={menuItems} />
        {children}
        <Footer />

    </>);
}

export default HomeLayout;