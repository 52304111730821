import React, { FC } from 'react';
import HomeLayout from '../../../components/layouts/HomeLayout';
import ContactForm from '../../../components/forms/contact-form/ContactForm';
import './style.css'
import { Alert, Box, Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField, TextareaAutosize, useMediaQuery, useTheme } from '@mui/material';
import heliceSmall from '../../../assets/img/helios 02.png';
import solarisSmall from '../../../assets/img/solaris 02.png';
import CounterInput from '../../../components/inputs/couter-input/CounterInput';
import { ErrorText } from 'components/forms/Error';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from 'config';

interface OrderSceneProps {

}

const OrderScene: FC<OrderSceneProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [submitting, setSubmitting] = React.useState(false)
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [submitError, setSubmitError] = React.useState(false)
    const formik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            company: '',
            phone: '',
            email: '',
            message: '',
            helios_qte: 0,
            solaris_qte: 0,
            offer: '',
            delivery_address: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            last_name: Yup.string().required('Champ requis'),
            company: Yup.string(),
            email: Yup.string().email('Adresse email invalide').required('Champ requis'),
            phone: Yup.string().matches(/^\+?(?:\d{1,3}[-\s.])?(?:\(\d{1,4}\)|\d{1,4})(?:[-\s.]?\d{1,4}){1,5}$/, 'Numéro de téléphone invalide').required('Champ requis'),
            message: Yup.string().required('Champ requis'),
            helios_qte: Yup.number().min(0, 'Amount must be a positive number'),
            solaris_qte: Yup.number().when('helios_qte', {
                is: (num: any) => num === undefined || num <= 0,
                then: () => Yup.number().min(1, 'Amount must be a positive number'),
            }),
            offer: Yup.string(),
            delivery_address: Yup.string(),
        }),
        onSubmit: (values) => {
            // Handle form submission here
            console.log(values);
            setSubmitting(true)
            axios.post(`${API_URL}/api/contacts`, { data: values }).
                then(() => {
                    setSubmitSuccess(true);
                    setSubmitError(false);
                }).catch((err) => {
                    setSubmitSuccess(false);
                    setSubmitError(true)
                }).finally(() => { setSubmitting(false) })
        },
    });
    console.log("formik", formik.values)
    return (<>
        <HomeLayout >
            {/* Left Gradient */}
            <div>
                <Box
                    sx={{
                        ...classes.container,
                        position: isMobile ?
                            'static' :
                            'absolute',
                        zIndex: -1,
                        padding: 0
                    }}
                >
                    <img src={heliceSmall} alt="heliceSmall" style={classes.image} />
                </Box>
            </div>
            <h2 style={{ textAlign: 'center' }}>
                {'Commandez votre support dès maintenant :'}
            </h2>
            {/* REGION 1 */}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 50,
                justifyContent: 'space-around',
                flexWrap: 'wrap',
            }}>
                <div>
                    <Grid container spacing={0.5} style={{ margin: '0 auto', maxWidth: isMobile ? '90%' : 560, }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={!!formik.errors.name}
                                InputLabelProps={{
                                    style: classes.InputLabelStyle
                                }}
                                inputProps={{
                                    style: classes.inputStyle
                                }}
                                label="Nom"
                                variant="standard"
                                fullWidth
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                style={classes.textField}
                            />
                            <ErrorText error={formik.errors.name} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={!!formik.errors.last_name}
                                InputLabelProps={{
                                    style: classes.InputLabelStyle
                                }}
                                inputProps={{
                                    style: classes.inputStyle
                                }}
                                name="last_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.last_name}
                                label="Prénom"
                                variant="standard"
                                fullWidth
                                style={classes.textField}
                            />
                            <ErrorText error={formik.errors.last_name} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formik.errors.company}
                                InputLabelProps={{
                                    style: classes.InputLabelStyle
                                }}
                                inputProps={{
                                    style: classes.inputStyle
                                }}
                                label="Société (facultatif)"
                                variant="standard"
                                fullWidth
                                style={classes.textField}
                                name="company"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.company}
                            />
                            <ErrorText error={formik.errors.company} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formik.errors.email}
                                InputLabelProps={{
                                    style: classes.InputLabelStyle,

                                }}
                                inputProps={{
                                    style: classes.inputStyle
                                }}
                                label="Adresse email"
                                variant="standard"
                                fullWidth
                                style={classes.textField}
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            <ErrorText error={formik.errors.email} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!formik.errors.phone}
                                InputLabelProps={{
                                    style: classes.InputLabelStyle
                                }}
                                inputProps={{
                                    style: classes.inputStyle

                                }}
                                label="Numéro de téléphone"
                                variant="standard"
                                fullWidth
                                style={classes.textField}
                                name="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            <ErrorText error={formik.errors.phone} />
                        </Grid>

                    </Grid>
                </div>
            </div>
            {/* REGION 2 */}
            <div style={isMobile ? { display: 'flex', flexDirection: 'column-reverse' } : {}}>
                <div >
                    <Box
                        sx={{
                            ...classes.container, position: isMobile ?
                                'relative' :
                                'absolute',
                            right: 0,
                            padding: 0,
                            top: 120
                        }}
                    >
                        <img src={solarisSmall}
                            alt="solarisSmall" style={classes.image}
                        />
                    </Box>
                </div>
                <Grid container spacing={1} style={{
                    maxWidth: isMobile ? '100%' : 500,
                    margin: isMobile ? '0 auto' : '0 25% auto',
                    marginBottom: 50,
                }}>
                    {/* Right Gradient */}
                    <Grid item xs={12}>
                        <CounterInput name="helios_qte" onChange={formik.setFieldValue} label="Quantité de Helios demandé(s) :" subLabel="Support avec 6 hélices (1,78m x 1,35m)" />
                    </Grid>
                    <Grid item xs={12}>
                        <CounterInput name="solaris_qte" onChange={formik.setFieldValue} label="Quantité de Solaris demandé(s) :" subLabel="Support avec 1 hélice (1,63m x 0,7m)" />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ flexDirection: isMobile ? 'column' : 'row', ...classes.inputContainer as any }}>
                            <div style={{ padding: 15 }}>
                                Offre souhaitée :
                            </div>
                            <div>
                                <div style={classes.checkboxGroup}>
                                    {/* Second form */}
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Achat"
                                        name="radio-buttons-group"
                                    >
                                        <div style={classes.checkbox}>
                                            <FormControlLabel
                                                disableTypography
                                                value="Achat"
                                                control={<Radio />}
                                                label="Achat"
                                                labelPlacement="end"
                                                name='offer'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        <div style={classes.checkbox}>
                                            <FormControlLabel
                                                disableTypography
                                                value="Court terme (Location)"
                                                control={<Radio />}
                                                label="Court terme (Location)"
                                                labelPlacement="end"
                                                name='offer'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            InputLabelProps={{
                                style: classes.InputLabelStyle
                            }}
                            inputProps={{
                                style: classes.inputStyle
                            }}
                            label="Addresse de livraison"
                            variant="standard"
                            fullWidth
                            style={{
                                borderRadius: 10,
                                fontFamily: "'Poppins', sans-serif !important",
                            }}
                            name='delivery_address'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.delivery_address}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            style={{
                                width: '98%', height: 70,
                                border: !!formik.errors.message ? '1px red solid' : '1px #0E1B4D solid',
                                borderRadius: 5,
                                padding: 5,
                                fontFamily: 'Poppins',
                                fontSize: 16
                            }}
                            name='message'
                            maxRows={10}
                            placeholder='Message (facultatif)'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                        />
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <Button
                            sx={{
                                background: '#0E1B4D',
                                border: '1px solid rgba(14, 27, 77, 0.7)',
                                borderRadius: 90,
                                margin: '0 50',
                                marginTop: '20px',
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {
                                submitting ?
                                    <CircularProgress size={20} color="inherit" /> :
                                    'Envoyer'
                            }
                        </Button>
                        <br />
                        <br />
                        <span style={{ textAlign: 'center', fontFamily: 'Poppings', fontSize: 14 }}>Notre équipe commerciale reviendra vers vous sous 3 jours ouvrés.</span>
                        <br />
                        {submitSuccess && <Alert severity="success">Votre message à été envoyé avec succés</Alert>}
                        {submitError && <Alert severity="error">Erreur lors de l'envoi, merci de réessayer plus tartd</Alert>}
                    </Grid>
                </Grid>
            </div>
        </HomeLayout >
    </>);
}

export default OrderScene;

// Custom styles
const useStyles = () => ({
    inputContainer: {
        display: 'flex',
    },
    checkboxGroup: {
        width: 322,
    },
    checkbox: {
        borderRadius: 10,
        border: '1px solid rgba(14, 27, 77, 0.70)',
        paddingLeft: 5,
        marginTop: 10,
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        overflow: 'hidden',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    title: {
        color: '#000',
        fontSize: 22,
        fontFamily: 'Roboto',
        fontWeight: 800,
    },
    inputStyle: {
        fontFamily: 'Poppins',
        border: '1px #0E1B4D solid',
        borderRadius: 5,
        padding: 15
    },
    InputLabelStyle: {
        fontFamily: 'Poppins',
        paddingLeft: 5
    },
    textField: {
        fontFamily: "'Poppins', sans-serif !important",
    },
});