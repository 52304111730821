// src/components/TeslaSlider.tsx
import React from 'react';
import HomeLayout from './layouts/HomeLayout';
// css
import './carousel.css'

export const TeslaSlider: React.FC<any> = () => {
    return (
        <HomeLayout>

            <div className="container">
                <div className="section" style={{backgroundColor: 'red'}}></div>
                <div className="section" style={{backgroundColor: 'green'}}></div>
                <div className="section" style={{backgroundColor: 'black'}}></div>
                <div className="section" style={{backgroundColor: 'yellow'}}></div>
                <div className="section" style={{backgroundColor: 'blue'}}></div>
            </div>
        </HomeLayout>);
}

export default TeslaSlider;
