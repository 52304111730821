import React from 'react';
import TextField from '@mui/material/TextField';

const PersonalInfoForm = () => {
  return (
    <form>
      <TextField label="Prénom" fullWidth InputLabelProps={{ shrink: true }} />
      <TextField label="Nom" fullWidth InputLabelProps={{ shrink: true }} />
      <TextField label="Email" fullWidth InputLabelProps={{ shrink: true }} />
      <TextField label="Numéro de téléphone" fullWidth InputLabelProps={{ shrink: true }} />
      <TextField label="Nom de la société" fullWidth InputLabelProps={{ shrink: true }} />
    </form>
  );
};

export default PersonalInfoForm;
