import { Alert, Button, CircularProgress, Grid, TextField, TextareaAutosize, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Phone, Email, LocationOn } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorText } from '../Error';
import axios from 'axios';
import { API_URL } from 'config';

interface ContactFormProps {
    title?: string;
    subTitle?: string;
    withCoordinates?: boolean;
    withTitle?: boolean;
    successCallback?: () => void;
    // callback?: (formik: any) => void;
}
export type RefHandler = {
    submitForm: () => void;
};

const ContactForm = React.forwardRef<RefHandler, ContactFormProps>(({
    title = `Besoin de renseignements ou d’une démonstration ?`,
    subTitle = `Contactez-nous !`,
    withCoordinates = true,
    withTitle = true,
    successCallback,
}, ref) => {
    const [submitting, setSubmitting] = React.useState(false)
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [submitError, setSubmitError] = React.useState(false)



    const formik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            company: '',
            phone: '',
            email: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            last_name: Yup.string().required('Champ requis'),
            company: Yup.string(),
            email: Yup.string().email('Adresse email invalide').required('Champ requis'),
            phone: Yup.string().matches(/^\+?(?:\d{1,3}[-\s.])?(?:\(\d{1,4}\)|\d{1,4})(?:[-\s.]?\d{1,4}){1,5}$/, 'Numéro de téléphone invalide').required('Champ requis'),
            message: Yup.string(),
        }),
        onSubmit: (values) => {

            // Handle form submission here
            console.log(values);
            setSubmitting(true);
            axios.post(`${API_URL}/api/contacts`, { data: values }).
                then(() => {
                    setSubmitSuccess(true);
                    setSubmitError(false);
                    successCallback?.();
                }).catch((err) => {
                    setSubmitSuccess(false);
                    setSubmitError(true);
                }).finally(() => { setSubmitting(false) })
        },
    });
    const handleParentSubmit = () => {

    }
    React.useImperativeHandle(ref, () => ({
        submitForm: () => {
            console.log("SUBBNITTIN", formik)
            formik.submitForm()
        }
    }), [formik]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 50,
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        }}>
            <div>
                {withTitle && <h2 style={{ textAlign: 'center' }}>{title}
                    <br />{subTitle}</h2>}
                <Grid container spacing={0.5} style={{ ...styles.formContainer, maxWidth: isMobile ? '90%' : 560, }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={!!formik.errors.name}
                            InputLabelProps={{
                                style: styles.InputLabelStyle
                            }}
                            inputProps={{
                                style: styles.inputStyle
                            }}
                            label="Nom"
                            variant="standard"
                            fullWidth
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            style={styles.textField}
                        />
                        <ErrorText error={formik.errors.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={!!formik.errors.last_name}
                            InputLabelProps={{
                                style: styles.InputLabelStyle
                            }}
                            inputProps={{
                                style: styles.inputStyle
                            }}
                            name="last_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                            label="Prénom"
                            variant="standard"
                            fullWidth
                            style={styles.textField}
                        />
                        <ErrorText error={formik.errors.last_name} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!formik.errors.company}
                            InputLabelProps={{
                                style: styles.InputLabelStyle
                            }}
                            inputProps={{
                                style: styles.inputStyle
                            }}
                            label="Société (facultatif)"
                            variant="standard"
                            fullWidth
                            style={styles.textField}
                            name="company"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company}
                        />
                        <ErrorText error={formik.errors.company} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!formik.errors.email}
                            InputLabelProps={{
                                style: styles.InputLabelStyle,

                            }}
                            inputProps={{
                                style: styles.inputStyle
                            }}
                            label="Adresse email"
                            variant="standard"
                            fullWidth
                            style={styles.textField}
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        <ErrorText error={formik.errors.email} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!formik.errors.phone}
                            InputLabelProps={{
                                style: styles.InputLabelStyle
                            }}
                            inputProps={{
                                style: styles.inputStyle
                            }}
                            label="Numéro de téléphone"
                            variant="standard"
                            fullWidth
                            style={styles.textField}
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                        <ErrorText error={formik.errors.phone} />
                    </Grid>
                    {withCoordinates && <><Grid item xs={12}>
                        <TextareaAutosize
                            style={{
                                width: '98%', height: 70,
                                border: !!formik.errors.message ? '1px red solid' : '1px #0E1B4D solid',
                                borderRadius: 5,
                                padding: 5,
                                fontFamily: 'Poppins',
                                fontSize: 16
                            }}
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            maxRows={10}
                            placeholder='Message'
                        />
                        <ErrorText error={formik.errors.message} />
                    </Grid>
                        <Grid item xs={12}>
                            <Button
                                sx={{
                                    background: '#0E1B4D',
                                    border: '1px solid rgba(14, 27, 77, 0.7)',
                                    borderRadius: 90,
                                    margin: '0 50',
                                    fontFamily: 'Poppins',
                                }}
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={styles.submitButton}
                                onClick={(e: any) => { formik.submitForm() }}
                            >
                                {
                                    submitting ?
                                        <CircularProgress size={20} color="inherit" /> :
                                        'Envoyer'
                                }
                            </Button>
                            <br />
                            <br />
                            {submitSuccess && <Alert severity="success">Votre message à été envoyé avec succés</Alert>}
                            {submitError && <Alert severity="error">Erreur lors de l'envoi, merci de réessayer plus tartd</Alert>}
                        </Grid></>}
                </Grid>
            </div>
            {withCoordinates && <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <div>
                    <h2>Coordonnées</h2>
                    <p style={{ display: 'flex', justifyContent: 'flex-start' }}><Phone style={{ marginRight: 5 }} /><span>+33 6 40 12 66 20</span></p>
                    <p style={{ display: 'flex', justifyContent: 'flex-start' }}><Email style={{ marginRight: 5 }} /><span>contact@stargetholographics.com</span></p>
                    <p style={{ display: 'flex', justifyContent: 'flex-start' }}><LocationOn style={{ marginRight: 5 }} />
                        <span><b>Starget Holographics</b>
                            <br />60 rue François 1ER
                            <br />75008, Paris
                            <br />France
                        </span>
                    </p>
                </div>
            </div>}
        </div>);
})

const styles = {
    formContainer: {
        margin: '0 auto',
    },
    textField: {
        fontFamily: "'Poppins', sans-serif !important",

    },
    submitButton: {
        fontFamily: 'Poppins',
    },
    inputStyle: {
        fontFamily: 'Poppins',
        border: '1px #0E1B4D solid',
        borderRadius: 5,
        padding: 15
    },
    InputLabelStyle: {
        fontFamily: 'Poppins',
        paddingLeft: 5
    },
}

export default ContactForm;