import { Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import logo from '../../../assets/img/logo.svg'
import { useNavigate } from 'react-router-dom';
interface FooterProps {

}

const Footer: FC<FooterProps> = () => {
    const navigate = useNavigate()
    return (<>
        <Box bgcolor="#0E1B4D" sx={{ zIndex: 10 }} color={'white'} py={4}>
            <Container>
                <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {/* Logo */}
                                <Typography onClick={() => { navigate('/') }} variant="h6" sx={{ fontFamily: 'Poppins', cursor: 'pointer' }}>
                                    <img src={logo} width={250 / 1.35} height={82 / 1.35} alt='logo-footer' />
                                </Typography>
                            </Grid>
                            <Grid item>
                                {/* Links */}
                                <Typography variant="body2" display={'flex'}>
                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins', padding: 2, cursor: 'pointer' }} onClick={() => { navigate('/produits/contact') }}>Nous contacter</Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                {/* Social Media Icons */}
                                <LinkedIn style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.linkedin.com/company/starget-holographics/', '_blank') }} />
                                <Instagram style={{ cursor: 'pointer' }} onClick={() => { window.open('https://instagram.com/stargetholographics?igshid=MzRlODBiNWFlZA==', '_blank') }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Second Row */}
                    <Grid item xs={12} display={'flex'} justifyContent={'space-around'} alignItems={'flex-end'}>
                        {/* First Column */}
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column">
                                <Grid item>
                                    {/* Company Information */}
                                    <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                                        +33 1 01 01 01 01
                                    </Typography>
                                    <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                                        contact@stargetholographics.com
                                    </Typography>
                                    <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                                        60 rue François 1er - 75008 Paris, France
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {/* Company Rights */}
                                    <br />
                                    <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                                        ©2023 Starget Holographics
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Second Column */}
                        <Grid item xs={12} sm={6} >
                            <Grid container justifyContent="flex-end" direction="column">
                                {/* Privacy Policy */}
                                <Typography variant="body2" style={{ fontFamily: 'Poppins', cursor: 'pointer' }} onClick={() => { navigate('/politque-de-confidentialite') }}>Politique de Confidentialité</Typography>
                                {/* CGU */}
                                <Typography variant="body2" style={{ fontFamily: 'Poppins', cursor: 'pointer' }} onClick={() => { navigate('/cgv') }}>CGV</Typography>
                                {/* Legal Mentions */}
                                <Typography variant="body2" style={{ fontFamily: 'Poppins', cursor: 'pointer' }} onClick={() => { navigate('/mentions-legales') }}>Mentions légales</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>);
}

export default Footer;
